import { useRef, useEffect, EffectCallback } from "react";
import { useAppContext } from "../AppContext";
import { deepEqual, jsonStringifyWithoutCircular } from "./Helpers";

export const usePrevious = (value: any, initialValue: any) => {
	const ref = useRef(initialValue);
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

/**
 * Tool to understand what caused the change in use effect
 * https://stackoverflow.com/a/59843241/963109
 * @param effectHook  // function to run (as in norman useEffect)
 * @param dependencies  // dependencies (as in norman useEffect)
 * @param dependencyNames // names of the dependencies in the same order as they are given (needed for debug information)
 */
export const useEffectDebugger = (effectHook: EffectCallback, dependencies: any[], dependencyNames: string[] = [], enabled: boolean = true) => {
	const previousDeps = usePrevious(dependencies, []);
	const { isDebugging } = useAppContext();

	const changedDeps = dependencies.reduce((accum, dependency, index) => {
		if (dependency !== previousDeps[index]) {
			const keyName = dependencyNames[index] || index;
			return {
				...accum,
				[keyName]: {
					before: previousDeps[index],
					after: dependency,
				},
			};
		}
		return accum;
	}, {});

	if (process.env.NODE_ENV !== "production" && isDebugging && enabled) {
		// ei lokiteta tuotannossa
		if (Object.keys(changedDeps).length) {
			Object.keys(changedDeps).forEach((key) => {
				const before = changedDeps[key].before;
				const after = changedDeps[key].after;
				const debugStringLength = 500;
				if (before !== after) {
					try {
						if (typeof before === "function" || typeof after === "function") {
							console.log(`dependencyfunction "${key}" changed`);
						} else {
							console.log(
								`"${key}" changed from: \n${
									before && jsonStringifyWithoutCircular(before) ? jsonStringifyWithoutCircular(before).slice(0, debugStringLength) : before
								}\n...to...\n${
									after && jsonStringifyWithoutCircular(after) ? jsonStringifyWithoutCircular(after).slice(0, debugStringLength) : after
								}`,
							);
						}
					} catch (e) {
						console.log(e);
					}
				}
			});
		}
	}
	// effecHook is not added to dependencies, so it will not run infinitely
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(effectHook, dependencies);
};

/**
 * Custom hook that implements functionality of `useEffect` hook,
 * with deep comparison of dependencies (does not trigger if the value is the same) and debugging capabilities.
 *
 * @param effectHook - The effect callback function to be executed when dependencies change.
 * @param dependencies - An object containing the dependencies to be compared.
 * @param debugging - Optional. A boolean indicating whether the debugging should be enabled. Default is `true`.
 */
export const useEffectDeepCompare = (effectHook: EffectCallback, dependencies: Record<string, any>, debugging: boolean = true) => {
	const dependenciesArray = Object.values(dependencies);
	const previousDeps = usePrevious(dependenciesArray, []);
	const { isDebugging } = useAppContext();

	const dependenciesChanged = dependenciesArray.some((dep, index) => !deepEqual(dep, previousDeps[index]));

	const changedDeps = dependenciesArray.reduce((accum, dependency, index) => {
		if (!deepEqual(dependency, previousDeps[index])) {
			const keyName = Object.keys(dependencies)[index];
			return {
				...accum,
				[keyName]: {
					before: previousDeps[index],
					after: dependency,
				},
			};
		}
		return accum;
	}, {});

	if (process.env.NODE_ENV !== "production" && isDebugging && debugging && dependenciesChanged) {
		if (Object.keys(changedDeps).length) {
			Object.keys(changedDeps).forEach((key) => {
				const before = changedDeps[key].before;
				const after = changedDeps[key].after;
				const debugStringLength = 500;
				if (before !== after) {
					try {
						if (typeof before === "function" || typeof after === "function") {
							console.info(`dependency function "${key}" changed`);
						} else {
							console.info(
								`"${key}" changed from: \n${
									before && jsonStringifyWithoutCircular(before) ? jsonStringifyWithoutCircular(before).slice(0, debugStringLength) : before
								}\n...to...\n${
									after && jsonStringifyWithoutCircular(after) ? jsonStringifyWithoutCircular(after).slice(0, debugStringLength) : after
								}`,
							);
						}
					} catch (e) {
						console.error(e);
					}
				}
			});
		}
	}

	useEffect(() => {
		if (dependenciesChanged) {
			return effectHook();
		}
		// effecHook is not added to dependencies, so it will not run infinitely
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dependenciesChanged, ...dependenciesArray]);
};
