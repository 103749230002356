import { AccountInfo } from "@azure/msal-browser";

export enum AuthenticationType {
	AzureSSO = "AzureSSO", // user has been logged in with Azure SSO
	Eezynet = "Eezynet", // useraccount is managed in Eezynet (for future purposes)
	Cixtranet = "Cixtranet", // useraccount is managed in Cixtranet
}

export enum UserType {
	Normal = "Normal", // e.g. all the client users
	Internal = "Internal", // user is an internal user
	Admin = "Admin", // Internal user with admin rights
}

export type User = {
	email?: string;
	avatar?: URL;
	userType?: UserType;
	customerId?: number;
	authenticationType: AuthenticationType;
	currentSurveyId?: number;
} & AccountInfo;
