import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { theme } from "./styles/theme";
import { AxiosInstance } from "axios";
import { apiConfig, tokenRequest } from "./AuthConfig";
import { paths } from "./Routes";
import { GetUserInfo } from "./api/Authentication";
import { useAxiosContext } from "./api/AxiosProvider";
import { useGetSurveyWithLanguageId } from "./api/Surveys";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { Eezynet } from "./models/Eezynet";
import { User, UserType } from "./models/User";
import { GetUserPhoto as GetUserPhotoFromMS } from "./services/MsGraphService";
import { Color } from "./utils/Colors";
import { createCtx } from "./utils/Helpers";

export interface AppError {
	message: string;
	debug?: string;
}

export enum SelectEventSource {
	NotSet = -999,
	LocalStorage = 1,
	SurveySelectionFromCixtranet = 2,
	URL = 3,
	ClickEvent = 4,
}

// näitä tarvitaan mm. alasvetovalikoissa
export enum PaletteOption {
	valueColors1 = "valueColors1", // these refer to the names of colors in theme.palette
	valueColors11 = "valueColors11",
	valueColors2 = "valueColors2",
	valueColors21 = "valueColors21",
	valueColors3 = "valueColors3",
	valueColors31 = "valueColors31",
	valueColors32 = "valueColors32",
}

export type AppContextType = {
	user?: User;
	error?: AppError;
	selectedCustomerId?: number | undefined;
	setSelectedCustomerId: (customer: number | undefined, importance: SelectEventSource) => void;
	selectedSurveyId?: number | undefined;
	setSelectedSurveyId: (surveyId: number | undefined, customerId: number | undefined, importance: SelectEventSource) => void;
	selectedOrganisationId?: number | undefined;
	setSelectedOrganisationId: (organisationId: number | undefined) => void;
	selectedOrganisationUnitId?: number | undefined;
	setSelectedOrganisationUnitId: (organisationUnitId: number | undefined) => void;
	selectedUiLanguage?: string;
	setSelectedUiLanguage: (language: string) => void;
	selectedSurveyLanguage?: string;
	setSelectedSurveyLanguage: (language: string) => void;
	isTestingExternalUser: boolean;
	setIsTestingExternalUser: (isTestingExternalUser: boolean) => void;
	isDebugging?: boolean;
	setIsDebugging: (isDebugging: boolean) => void;
	signIn?: () => void;
	signOut?: () => void;
	getApiTokenAsync: () => Promise<string>;
	displayError: (message: string, debug?: string) => void;
	clearError?: () => void;
	authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
	isSeperateUiLanguage?: boolean;
	setIsSeperateUiLanguage: (isSeperateUiLanguage: boolean) => void;
	palettes: { [key in PaletteOption]: Color[] };
	simulationParams?: Eezynet.SimulationParams | undefined;
	setSimulationParams: (simulationParams: Eezynet.SimulationParams | undefined) => void;
	combineUnitIds?: number[] | undefined;
	setCombineUnitIds: (unitIds: number[] | undefined) => void;
};

export const [useAppContext, AppContextProvider] = createCtx<AppContextType>();

interface AppContextProps {
	children: React.ReactNode;
}

/**
 * Provides context (state) to be used for application wide data such as currently logged in user or currently selected survey
 */
export function AppContext({ children }: AppContextProps) {
	const defaultAppContext = CreateDefaultAppContext();
	return <AppContextProvider value={defaultAppContext}>{children}</AppContextProvider>;
}

const CreateDefaultAppContext = () => {
	const axiosInstance = useAxiosContext();
	const msal = useMsal();
	const isAuthenticated: boolean = useIsAuthenticated();
	const [user, setUser] = useState<User | undefined>(undefined);
	const [error, setError] = useState<AppError | undefined>(undefined);

	// useLocalStorage returns null before user is set, meaning types are not correct before that
	// although once user is found, the types are correct
	// TODO check if this is a problem
	const [selectedCustomerId, setSelectedCustomerId] = useLocalStorage<number | undefined>("selectedCustomerId", user);
	const [selectedSurveyId, setSelectedSurveyId] = useLocalStorage<number | undefined>("selectedSurveyId", user);
	const [selectedOrganisationId, setSelectedOrganisationId] = useLocalStorage<number | undefined>("selectedOrganisationId", user);
	const [selectedOrganisationUnitId, setSelectedOrganisationUnitId] = useLocalStorage<number | undefined>("selectedOrganisationUnitId", user);
	const [selectedUiLanguage, setSelectedUiLanguage] = useLocalStorage<string | undefined>("selectedUiLanguage", user);
	const [selectedSurveyLanguage, setSelectedSurveyLanguage] = useLocalStorage<string | undefined>("selectedSurveyLanguage", user);
	const [isDebugging, setIsDebugging] = useLocalStorage<boolean | undefined>("isDebugging", user);
	const [isTestingExternalUser, setIsTestingExternalUser] = useLocalStorage<boolean | undefined>("isTestingExternalUser", user);
	const [isSeperateUiLanguage, setIsSeperateUiLanguage] = useLocalStorage<boolean | undefined>("isSeperateUiLanguage", user);
	const [simulationParams, setSimulationParams] = useLocalStorage<Eezynet.SimulationParams | undefined>("simulationParams", user);
	const [combineUnitIds, setCombineUnitIds] = useLocalStorage<number[] | undefined>("combineUnitIds", user);
	// create palettes from theme based on paletteOptions so that it is a record that has properties for each paletteOption and value is respective palette from themes.palette
	const palettes = Object.values(PaletteOption).reduce(
		(acc, paletteKey) => {
			acc[paletteKey as PaletteOption] = theme.palette[paletteKey as keyof typeof theme.palette] as Color[];
			return acc;
		},
		{} as { [key in PaletteOption]: Color[] },
	);
	// TODO: alla olevan asian toteutus on jäänyt kesken
	// const [latestSurveyIdFromAPI, setLatestSurveyIdFromAPI] = useLocalStorage<number | undefined>("latestSurveyIdFromAPI", user);

	// Asetetaan tutkimuksen kieli, jottei sitä tarvitse asettaa joka hakuun erikseen
	axiosInstance.defaults.params = { LanguageCulture: selectedSurveyLanguage };

	//#region Organisation
	const _setSelectedOrganisationId = useCallback(
		(organisationId: number | undefined) => {
			if (user && organisationId !== selectedOrganisationId) {
				// jos organisaatio vaihtuu, nollataan myös organisaatioyksikkö
				setSelectedOrganisationUnitId(undefined);
				setCombineUnitIds(undefined);
			}
			setSelectedOrganisationId(organisationId);
		},
		[selectedOrganisationId, setSelectedOrganisationId, setSelectedOrganisationUnitId, user, setCombineUnitIds],
	);
	//#endregion Organisation

	//#region Survey

	// viritetään reactQUery valmiiksi. Kun kysely valmistuu, niin asetetaan customerId
	const surveyIdToFetch = useRef<number | undefined>(undefined);
	const customerIdTHatHasBeenSet = useRef<number | undefined>(undefined);
	const { data: survey } = useGetSurveyWithLanguageId(surveyIdToFetch.current, selectedSurveyLanguage, !!surveyIdToFetch.current, simulationParams);
	useEffect(() => {
		// jos customerId on jo asetettu, niin ei aseteta sitä uudestaan
		if (survey && survey.customerId !== customerIdTHatHasBeenSet.current) {
			setSelectedCustomerId(survey.customerId);
			customerIdTHatHasBeenSet.current = survey.customerId;
		}
	}, [survey, setSelectedCustomerId]);

	useEffect(() => {
		// set survey language to master language if it is not in the survey languages
		if (
			survey &&
			survey?.languages.some((lang) => lang.culture.toLowerCase() === selectedSurveyLanguage?.toLowerCase()) === false &&
			survey?.masterLanguage?.culture
		) {
			setSelectedSurveyLanguage(survey?.masterLanguage?.culture);
		}
	}, [survey, setSelectedSurveyLanguage, selectedSurveyLanguage]);

	const resetSimulation = useCallback(() => {
		setIsTestingExternalUser(undefined);
		setSimulationParams(undefined);
	}, [setIsTestingExternalUser, setSimulationParams]);

	const surveyIdHasBeenSetImportance = useRef(SelectEventSource.NotSet);
	const _setSelectedSurveyId = useCallback(
		(surveyId: number | undefined, customerId: number | undefined, importance: SelectEventSource) => {
			const changevalue = (importance ?? SelectEventSource.NotSet) >= surveyIdHasBeenSetImportance.current; // jos importance on suurempi tai sama kuin aiempi (esim. click voi tulla monta kertaa), niin vaihdetaan
			if (isDebugging) {
				console.log(
					`%c changevalue: ${changevalue}, _setSelectedSurveyId: ${surveyId}, ${customerId}, ${importance}, ${surveyIdHasBeenSetImportance.current}, ${SelectEventSource[importance]}`,
					"color: " + (changevalue ? "#090" : "#666"),
				);
			}

			if (!changevalue) return;
			setSelectedCustomerId(customerId); // vaikka olisi undefined, niin asetetaan arvo, koska se päivyttyy fetch:n jälkeen
			customerIdTHatHasBeenSet.current = customerId;
			// if customerId is undefined and surveyId is not, then we need to fetch customerId from useGetSurvey
			if (!customerId && !!surveyId) {
				surveyIdToFetch.current = surveyId;
			}
			setSelectedSurveyId(surveyId);
			surveyIdHasBeenSetImportance.current = importance ?? SelectEventSource.NotSet;
			_setSelectedOrganisationId(undefined); // tämä nollaan myös organisation Unit:n
			resetSimulation(); // nollataan simulointi tutkimuksen vaihtuessa
		},
		[_setSelectedOrganisationId, isDebugging, setSelectedCustomerId, setSelectedSurveyId, resetSimulation],
	);

	//#region Customer
	const _setSelectedCustomerId = useCallback(
		(customerId: number | undefined, importance: SelectEventSource) => {
			if (isDebugging) {
				console.log(`_setSelectedCustomerId: ${customerId}, ${importance}`);
			}
			// HUOM! ei aseteta customerId:tä, koska se asetetaan automaattisesti surveyId:n yhteydessä
			_setSelectedSurveyId(undefined, customerId, importance);
		},
		[_setSelectedSurveyId, isDebugging],
	);
	//#endregion Customer

	const displayError = useCallback((message: string, debug?: string) => {
		setError({ message, debug });
	}, []);

	const clearError = useCallback(() => {
		setError(undefined);
	}, []);

	// Used by the Graph SDK to authenticate API calls
	const authProvider = useMemo(
		() =>
			new AuthCodeMSALBrowserAuthenticationProvider(msal.instance as PublicClientApplication, {
				account: msal.instance.getActiveAccount()!,
				scopes: apiConfig.b2cScopes,
				interactionType: InteractionType.Popup,
			}),
		[msal.instance],
	);

	// Gets API token from MSAL
	const getApiToken = useCallback(async (): Promise<string> => {
		return await msal.instance
			.acquireTokenSilent({
				...tokenRequest,
				account: msal.instance.getActiveAccount()!,
			})
			.then((response) => response.accessToken);
	}, [msal.instance]);

	// Get the user info and photo from Microsoft Graph
	const GetUserPhoto = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider) => {
		let blobUrl = undefined;
		let image: Blob | null = null;
		try {
			// TODO: enable when we have a working API
			// Get the user profile picture from Microsoft Graph
			if (false) image = await GetUserPhotoFromMS(authProvider!);
		} catch {
			image = null;
		} finally {
			if (image !== null) {
				const url = window.URL || window.webkitURL;
				blobUrl = url.createObjectURL(image);
			}
		}
		return blobUrl;
	};

	const GetUserInformation = useCallback(async (axiosInstance: AxiosInstance) => {
		const userInfo = (await GetUserInfo(axiosInstance)).data;
		return userInfo;
	}, []);

	const account = msal.instance.getActiveAccount();

	const isFetching = useRef(false);

	// TODO: latestSurveyIdFromAPI on kokonaan toteuttamatta, ao koodit liittyy sen kokeiluihin

	// const surveyIdFromLocalStorageHasBeenSet = useRef(false);
	// const [newSurveyId, setNewSurveyId] = useState<number | undefined>(undefined);

	// useEffect(() => {
	//     _setSelectedSurveyId(latestSurveyIdFromAPI, undefined, SelectEventSource.LocalStorage);
	// }, [_setSelectedSurveyId, latestSurveyIdFromAPI]);

	// useEffect(() => {
	//     // katsotaan onko localstoragessa jo tämä surveyId
	//     if (latestSurveyIdFromAPI !== null && (!surveyIdFromLocalStorageHasBeenSet.current || newSurveyId !== latestSurveyIdFromAPI)) {
	//         _setSelectedSurveyId(newSurveyId, undefined, SelectEventSource.SurveySelectionFromCixtranet);
	//         setLatestSurveyIdFromAPI(newSurveyId);
	//         surveyIdFromLocalStorageHasBeenSet.current = true;
	//     }
	// }, [_setSelectedSurveyId, latestSurveyIdFromAPI, newSurveyId, setLatestSurveyIdFromAPI]);

	// const updateSelectedSurveyIfNeeded = useCallback(
	//     (surveyId: number | undefined) => {
	//         if (surveyId !== undefined && surveyId !== latestSurveyIdFromAPI) {
	//             _setSelectedSurveyId(surveyId, undefined, SelectEventSource.SurveySelectionFromCixtranet);
	//             setLatestSurveyIdFromAPI(surveyId);
	//         }
	//     },
	//     [_setSelectedSurveyId, latestSurveyIdFromAPI, setLatestSurveyIdFromAPI]
	// );

	// // kun user muuttuu, niin tarkistetaan onko localstoragessa jo tämä surveyId
	// // homma on todella monimutkainen, sillä ennen kun user on olemassa, niin latestSurveyIdFromAPI on null
	// const ValueThatWasSetoLatestSurveyIdFromAPI = useRef<number | null>(null);
	// useEffect(() => {
	//     if (!user) return;
	//     if (
	//         !!user.currentSurveyId &&
	//         (user.currentSurveyId !== latestSurveyIdFromAPI || ValueThatWasSetoLatestSurveyIdFromAPI.current !== null) &&
	//         ValueThatWasSetoLatestSurveyIdFromAPI.current !== user.currentSurveyId &&
	//         (!selectedSurveyId || !!user.currentSurveyId)
	//     ) {
	//         ValueThatWasSetoLatestSurveyIdFromAPI.current = user.currentSurveyId;
	//         _setSelectedSurveyId(user.currentSurveyId, undefined, SelectEventSource.SurveySelectionFromCixtranet);
	//         setLatestSurveyIdFromAPI(user.currentSurveyId);
	//     }
	// }, [_setSelectedSurveyId, latestSurveyIdFromAPI, selectedSurveyId, setLatestSurveyIdFromAPI, user]);

	const fetchData = useCallback(async () => {
		if (isFetching.current) return;
		if (user) return;
		if (!account) return;
		isFetching.current = true;
		const blobUrl = await GetUserPhoto(authProvider);
		const userInfo = await GetUserInformation(axiosInstance);
		setUser({
			...userInfo,
			...account,
			avatar: blobUrl ? new URL(blobUrl) : undefined,
			username: account.username ? account.username : userInfo.username,
		});

		// TODO: ongelma on, että vaikkayläpuolella on asetettu setUser, niin seuvaavassa rivissä user on edelleen undefined
		isFetching.current = false;
	}, [GetUserInformation, account, authProvider, axiosInstance, user]);

	useEffect(() => {
		fetchData();
	}, [GetUserInformation, authProvider, axiosInstance, fetchData, msal.instance]);

	const signIn = useCallback(async () => {
		await msal.instance.loginRedirect({
			//scopes: scopes,
			scopes: apiConfig.b2cScopes,
			prompt: "select_account",
		});
	}, [msal.instance]);

	const signOut = useCallback(async () => {
		await msal.instance.logoutRedirect();
		setUser(undefined);
	}, [msal.instance]);

	useEffect(() => {
		const urlPathname = window.location.pathname;
		const autoRedirectToSignIn = () => {
			if (urlPathname.toLowerCase() === paths.changepassword.path.toLowerCase() || urlPathname.toLowerCase() === paths.forgotpassword.path.toLowerCase())
				return;
			// Checks should prevent infinite loop of signing in
			if (!isAuthenticated && msal.inProgress === InteractionType.None) {
				signIn();
			}
		};
		autoRedirectToSignIn();
	}, [isAuthenticated, msal.inProgress, signIn]);

	const contextValue = useMemo(() => {
		return {
			user: { ...user, userType: isTestingExternalUser ? UserType.Normal : user?.userType } as User,
			error,
			selectedCustomerId,
			setSelectedCustomerId: _setSelectedCustomerId,
			selectedSurveyId,
			setSelectedSurveyId: _setSelectedSurveyId,
			selectedOrganisationId,
			setSelectedOrganisationId: _setSelectedOrganisationId,
			selectedOrganisationUnitId,
			setSelectedOrganisationUnitId,
			selectedUiLanguage,
			setSelectedUiLanguage,
			selectedSurveyLanguage,
			setSelectedSurveyLanguage,
			isDebugging,
			setIsDebugging,
			isTestingExternalUser: !!isTestingExternalUser,
			setIsTestingExternalUser,
			signIn,
			signOut,
			getApiTokenAsync: getApiToken,
			// getApiTokenWithCallback,
			displayError,
			clearError,
			authProvider,
			isSeperateUiLanguage,
			setIsSeperateUiLanguage,
			palettes,
			simulationParams,
			setSimulationParams,
			combineUnitIds,
			setCombineUnitIds,
		};
	}, [
		user,
		isTestingExternalUser,
		error,
		selectedCustomerId,
		_setSelectedCustomerId,
		selectedSurveyId,
		_setSelectedSurveyId,
		selectedOrganisationId,
		_setSelectedOrganisationId,
		selectedOrganisationUnitId,
		setSelectedOrganisationUnitId,
		selectedUiLanguage,
		setSelectedUiLanguage,
		selectedSurveyLanguage,
		setSelectedSurveyLanguage,
		isDebugging,
		setIsDebugging,
		setIsTestingExternalUser,
		signIn,
		signOut,
		getApiToken,
		displayError,
		clearError,
		authProvider,
		isSeperateUiLanguage,
		setIsSeperateUiLanguage,
		palettes,
		simulationParams,
		setSimulationParams,
		combineUnitIds,
		setCombineUnitIds,
	]);

	return contextValue;
};
