import { keepPreviousData, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ApiRootUrl, apiRoutes } from "../Routes";
import { Eezynet } from "../models/Eezynet";
import { pathToUrl } from "../utils/pathToUrl";
import { useAxiosContext } from "./AxiosProvider";

export const useGetCustomerInfinite = ({ pageSize = 100 }: { pageSize?: number }) => {
	const axiosInstance = useAxiosContext();
	return useInfiniteQuery<Eezynet.PagedResult<Eezynet.Customer>, AxiosError>({
		queryKey: ["getCustomersInfinite"],
		initialPageParam: 0,
		queryFn: async ({ pageParam }) => {
			const pageNumber = typeof pageParam === "number" ? pageParam : 0;
			const URL = ApiRootUrl + pathToUrl(apiRoutes.getCustomers, { pageNumber, pageSize: pageSize });
			const { data } = await axiosInstance.get<Eezynet.PagedResult<Eezynet.Customer>>(URL);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage.pageNumber < lastPage.totalPages ? lastPage.pageNumber + 1 : null;
		},
	});
};

export function useGetCustomers({ pageNumber = 1, pageSize = 999, enabled = true }: { pageNumber?: number; pageSize?: number; enabled?: boolean }) {
	const axiosInstance = useAxiosContext();
	// create paged query with axios instance that uses the pagesize and currentpage
	const URL = pathToUrl(apiRoutes.getCustomers, { pageNumber: pageNumber, pageSize: pageSize });
	const params = { pageSize };
	return useQuery<Eezynet.PagedResult<Eezynet.Customer>, AxiosError>({
		queryKey: [URL, pageNumber, params],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.PagedResult<Eezynet.Customer>>(ApiRootUrl + URL, { params });
			return data;
		},
		enabled,
		placeholderData: keepPreviousData,
	});
}

export const useGetCustomer = (id: number | undefined, enabled = true) => {
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.getCustomerById, { id });
	return useQuery<Eezynet.Customer, AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.Customer>(ApiRootUrl + URL);
			return data;
		},
		enabled,
	});
};
