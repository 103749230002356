import "./wdyr";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
// MSAL imports
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserHistory } from "history";
import App from "./App";
import { msalConfig } from "./AuthConfig";
import { paths } from "./Routes";

// import reportWebVitals from "./reportWebVitals";

export const msalInstance = new PublicClientApplication(msalConfig);
const initialize = async () => {
	await msalInstance.initialize().catch((e) => console.log("MSAL failed to initialize", e));

	// Check if there are already accounts in the browser session
	// If so, set the first account as the active account
	const accounts = msalInstance.getAllAccounts();
	if (accounts && accounts.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}

	msalInstance.addEventCallback((event: EventMessage) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			// Set the active account - this simplifies token acquisition
			const authResult = event.payload as AuthenticationResult;
			msalInstance.setActiveAccount(authResult.account);
		} else if (event.eventType === EventType.LOGIN_FAILURE && event.error?.message.includes("AADB2C90118")) {
			let history = createBrowserHistory();
			history.push(paths.forgotpassword.path);
		}
	});
};

initialize();

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false, // default: true
		},
	},
});
const root = createRoot(document.getElementById("root")!);
root.render(
	<React.StrictMode>
		<Router>
			<QueryClientProvider client={queryClient}>
				<App pca={msalInstance} />
			</QueryClientProvider>
		</Router>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
