import { initReactI18next } from "react-i18next";
import axios from "axios";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend, { HttpBackendOptions } from "i18next-http-backend";
import { RequestCallback } from "i18next-http-backend";
import { ApiRootUrl, apiRoutes } from "../Routes";

const options = {
	order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator"],
	lookupQuerystring: "lng",
	//Täältä ei pystynyt helposti kutsumaan noita kantaan tallennettuja kieliä koska hook säännöt. Tässä nyt kuitenkin toimiva versio.
	convertDetectedLanguage: (lng: string) => {
		if (lng === "en") {
			return "en-US";
		} else if (lng === "fi") {
			return "fi-FI";
		} else if (!lng.includes("-")) {
			return "en-US";
		}
		return lng;
	},
};

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: options,
		debug: false,
		interpolation: { escapeValue: false },
		keySeparator: false,
		nsSeparator: false,
		load: "currentOnly",
		fallbackLng: false,
		backend: {
			crossDomain: true,
			loadPath: ApiRootUrl + apiRoutes.getTranslations + "{{lng}}",
			request: (options: HttpBackendOptions, url: string, payload: {} | string, callback: RequestCallback) => {
				axios
					.get(url)
					.then((res) => {
						callback(null, { status: 200, data: res.data });
					})
					.catch((err) => callback(err, { status: 500, data: "" }));
			},
		},
	});

export default i18n;
