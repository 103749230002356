import React, { ReactNode, createContext, useContext } from "react";
import { AxiosInstance } from "axios";
import { useAxiosContext } from "../api/AxiosProvider";
import { getRespondentGroupInformationSingle } from "../api/RepondentGroups";
import { Eezynet } from "../models/Eezynet";
import { createStringHash } from "../utils/Helpers";

type GetRespondentGroupParams = {
	respondentGroup: Eezynet.RespondentGroup;
	selectedSurveyLanguage?: string;
	selectedSurveyId?: number;
};

export class RespondentGroupService {
	private requestQueue: (() => Promise<void>)[] = [];
	private cache: Map<string, Eezynet.RespondentGroup> = new Map();
	private axiosInstance: AxiosInstance;
	private isDebugMode = false;
	private isQueueProcessing = false;

	constructor(axiosInstance: AxiosInstance, isDebugMode?: boolean) {
		this.axiosInstance = axiosInstance;
		this.isDebugMode = !!isDebugMode;
	}

	private async processQueue(): Promise<void> {
		if (this.isQueueProcessing) return;
		this.isQueueProcessing = true;

		while (this.requestQueue.length > 0) {
			const nextTask = this.requestQueue.shift();
			if (nextTask) {
				if (this.isDebugMode) {
					console.log("Processing next task, queue length: " + this.requestQueue.length + " tasks remaining");
				}
				await nextTask();
			}
		}

		this.isQueueProcessing = false;
	}

	getRespondentGroup({ respondentGroup, selectedSurveyLanguage, selectedSurveyId }: GetRespondentGroupParams): Promise<Eezynet.RespondentGroup> {
		return new Promise<Eezynet.RespondentGroup>(async (resolve) => {
			// Jos respondentGroup:lta puuttuu luokan nimi, ei sill� toimi sanitized. Luodaan new Eezynet.RespondentGroup sen varalta
			const compGroup = respondentGroup.comparisonGroup ? new Eezynet.RespondentGroup(respondentGroup.comparisonGroup).sanitized : undefined;
			const newResp = new Eezynet.RespondentGroup(respondentGroup);
			const paramsWithBareRespondentGroup = {
				newResp: {
					...newResp.sanitized,
					comparisonGroup: compGroup,
				},
				selectedSurveyLanguage,
			};

			const cacheKey = await createStringHash(paramsWithBareRespondentGroup);
			const task = async () => {
				if (this.cache.has(cacheKey)) {
					if (this.isDebugMode) {
						console.log("%cRespondent group data found in cache", "color: green");
					}
					resolve(this.cache.get(cacheKey)!);
					return;
				}

				if (this.isDebugMode) {
					console.log("%cRespondent group data not found in cache, fetching from API", "color: orange");
				}

				const data = await getRespondentGroupInformationSingle(this.axiosInstance, respondentGroup, selectedSurveyLanguage, selectedSurveyId);

				// Cache the newly fetched data
				this.cache.set(cacheKey, data);

				// Resolve the promise
				resolve(data);
			};

			this.requestQueue.push(task);

			if (!this.isQueueProcessing) {
				this.processQueue();
			}
		});
	}

	// Function to clear cache
	clearCache(): void {
		if (this.isDebugMode) console.log("Cache size before clearing: " + this.cache.size);
		this.cache.clear();
	}

	// Function to get cache size
	getCacheSize(): number {
		return this.cache.size;
	}
}

const RespondentGroupServiceContext = createContext<RespondentGroupService | null>(null);

export const useRespondentGroupService = () => {
	const context = useContext(RespondentGroupServiceContext);
	if (!context) {
		throw new Error("useRespondentGroupService must be used within a RespondentGroupServiceProvider");
	}
	return context;
};

interface RespondentGroupServiceProviderProps {
	children: ReactNode;
}

export const RespondentGroupServiceProvider: React.FC<RespondentGroupServiceProviderProps> = ({ children }) => {
	const axiosInstance = useAxiosContext();
	const service = new RespondentGroupService(axiosInstance, false);
	return <RespondentGroupServiceContext.Provider value={service}>{children}</RespondentGroupServiceContext.Provider>;
};
