import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { LinearProgress } from "@mui/material";
import { useAppContext } from "./AppContext";
import { useAutoSelectSurveyAndCustomer } from "./hooks/useAutoSelectSurveyAndCustomer";
import { User, UserType } from "./models/User";
import Errors from "./pages/Errors";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";

const Customers = lazy(() => import("./pages/Customers"));
const Customer = lazy(() => import("./pages/Customer"));
const Planning = lazy(() => import("./pages/Planning"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Results = lazy(() => import("./pages/Results"));
const Profile = lazy(() => import("./pages/Profile"));
const Settings = lazy(() => import("./pages/Settings"));
const Utilization = lazy(() => import("./pages/Utilization"));
const Survey = lazy(() => import("./pages/Survey"));
const Translations = lazy(() => import("./pages/Translations"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const TranslationsKeys = lazy(() => import("./pages/TranslationKeys"));
const Analysis = lazy(() => import("./pages/Analysis"));

export type Path = {
	title: string;
	path: string;
	element: JSX.Element;
	restrictToUserTypes?: UserType[];
	translationKey?: string;
	icon?: JSX.Element;
};
type Paths = Record<string, Path>;

export const useTranslatedPaths = (): Paths => {
	const { t } = useTranslation();
	const translatedPaths: Paths = {};

	Object.entries(paths).forEach(([key, path]) => {
		translatedPaths[key] = { ...path, title: t(path.title) };
	});

	return translatedPaths;
};

// Note that these keys are used enywhere in the application to refer to a page
export const paths: Paths = {
	home: { path: "/", element: <Home />, title: "home" },
	customers: { path: "/customers", element: <Customers />, title: "customers" },
	customer: { path: "/customer/:id", element: <Customer />, restrictToUserTypes: [UserType.Internal, UserType.Admin], title: "customer" },
	survey: { path: "/survey/:id", element: <Survey />, restrictToUserTypes: [UserType.Internal, UserType.Admin], title: "survey" },
	planning: { path: "/planning", element: <Planning />, restrictToUserTypes: [UserType.Internal, UserType.Admin], title: "planning" },
	dashboard: { path: "/dashboard", element: <Dashboard />, restrictToUserTypes: [UserType.Admin], title: "menu_dashboard" },
	utilization: { path: "/utilization", element: <Utilization />, restrictToUserTypes: [UserType.Internal, UserType.Admin], title: "utilization" },
	translations: {
		path: "/translations",
		element: <Translations />,
		restrictToUserTypes: [UserType.Admin],
		icon: <LanguageIcon fontSize="small" />,
		title: "menu_translations",
	},
	errors: {
		path: "/errors",
		element: <Errors />,
		restrictToUserTypes: [UserType.Admin],
		icon: <ErrorIcon fontSize="small" />,
		title: "menu_errors",
	},
	results: { path: "/results", element: <Results />, title: "results" },
	resultsBySurveyId: { path: "/results\\?surveyId=:surveyId", element: <Results />, title: "results" },
	resultsByReportId: { path: "/results\\?reportId=:reportId", element: <Results />, title: "results" },
	analysis: { path: "/analysis", element: <Analysis />, title: "analysis" },
	analysisWithId: { path: "/analysis/:id", element: <Analysis />, title: "analysis" },
	profile: {
		path: "/profile",
		element: <Profile />,
		icon: <PersonIcon fontSize="small" />,
		restrictToUserTypes: [UserType.Internal, UserType.Admin],
		title: "menu_profile",
	},
	settings: {
		path: "/settings",
		element: <Settings />,
		icon: <SettingsIcon fontSize="small" />,
		restrictToUserTypes: [UserType.Internal, UserType.Admin],
		title: "settings",
	},
	changepassword: { path: "/changepassword", element: <ChangePassword />, title: "change_password" },
	forgotpassword: { path: "/forgotpassword", element: <ForgotPassword />, title: "request_password_reset" },
	translationsKeys: { path: "/translationskeys", element: <TranslationsKeys />, title: "menu_translations" },
};

// tekee yllä määritellyistä poluista Routes -olion react-router-dom vaatimalla tavalla
export const PageRoutes = () => {
	const { user, isTestingExternalUser } = useAppContext();

	useAutoSelectSurveyAndCustomer();

	return (
		<Suspense fallback={<LinearProgress />}>
			{!!user && (
				<Routes>
					{Object.entries(paths).map((r) => {
						const p = r[1] as Path;
						// katsotaan onko käyttäjällä oikeus tähän polkuun, jos ei niin piirretään etusivu
						const isAllowed =
							!p.restrictToUserTypes ||
							p.restrictToUserTypes?.length === 0 ||
							(user?.userType && p.restrictToUserTypes?.includes(user.userType)) ||
							isTestingExternalUser;
						return <Route key={r[0]} path={p.path} element={isAllowed ? p.element : paths.home.element} />;
					})}
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			)}
		</Suspense>
	);
};

export const filterRestrictedPaths = (paths: Path[], user?: User): Path[] => {
	return paths.filter(
		(item) => !item.restrictToUserTypes || item.restrictToUserTypes.length === 0 || item.restrictToUserTypes?.includes(user?.userType ?? UserType.Normal),
	);
};
export const ApiRootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_EEZY_URL : "https://localhost:7103";

export const apiRoutes = {
	getUserInfo: "/api/User/GetUserInfo",

	getNewPasswordEmail: "/api/Authentication/RequestNewPassword",
	updateCixtranetPassword: "/api/Authentication/ChangeCixtranetUserPassword",

	getCustomerById: "/api/Customers/:id",
	getCustomers: "/api/Customers\\?pageNumber=:pageNumber&pageSize=:pageSize",
	getSurveysByCustomerId: "/api/Customers/:id/surveys\\?pageNumber=:pageNumber&pageSize=:pageSize",

	getSurveyById: "/api/Surveys/:id",
	getPreviousSurveys: "/api/Surveys/:surveyid/PreviousSurveys",
	getOrganisationById: "/api/Surveys/:surveyid/Organisations/:organisationid",
	getOrganisationsBySurveyId: "/api/Surveys/:surveyid/Organisations",
	resetSurveyCache: "/api/Surveys/:surveyid/ResetSurveyCache",
	getLatestSurveyIdForUser: "/api/Surveys/GetLatestSurveyIdForUser",
	getStatistic: "/api/Surveys/:surveyid/GetStatistic",
	getUsergroupPermissions: "/api/Surveys/:surveyid/GetUsergroupPermissions",
	saveUsergroup: "/api/Surveys/:surveyid/SaveUsergroup",
	getSurveyTranslations: "/api/Surveys/:surveyid/Translations\\?culture=:culture",
	getSurveyTranslationKeys: "/api/Surveys/:surveyid/TranslationKeys",
	getSurveyTranslation: "/api/Surveys/:surveyid/Translation\\?translationkey=:translationkey",

	getErrors: "/api/Errors\\?pageNumber=:pageNumber?&pageSize=:pageSize?&filters=:filters?&sorting=:sorting?&searchTerm=:searchTerm?",

	getNumericResults: "/api/Results/GetNumericQuestionResults",
	getIndexResults: "/api/Results/GetIndexResults",
	getTextualResults: "/api/Results/GetTextualQuestionResults\\?pageNumber=:pageNumber&pageSize=:pageSize",
	getRespondentGroupHash: "/api/Results/GetRespondentGroupHash",
	getRespondentGroupsHashes: "/api/Results/GetRespondentGroupsHashes",
	getRespondentGroupInformation: "/api/Results/GetRespondentGroupInformation",
	getQuestionCounts: "/api/Results/GetSurveyQuestionCounts",
	getMainFinding: "/api/Results/GetMainFinding",
	getNumericGroupResults: "/api/Results/GetNumericQuestiongroupResults",

	getTranslations: "/api/Translation?languageCulture=",
	getAllFullTranslations: "/api/Translation/GetAllSystemTranslations\\?culture=:culture",
	saveSystemTranslation: "/api/Translation/SaveSystemTranslation",
	getUILanguages: "/api/Translation/getUILanguages",

	getReportConfigurationsBySurveyId: `/api/ReportConfigurations\\?surveyId=:surveyId`,
	getReportConfigurationById: `/api/ReportConfigurations/:id`,
	createReportConfiguration: `/api/ReportConfigurations\\?surveyId=:surveyId`,
	updateReportConfiguration: `/api/ReportConfigurations/:id`,
	deleteReportConfiguration: `/api/ReportConfigurations/:id`,
};
