import { PropsWithChildren, useMemo } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios, { AxiosError, AxiosHeaderValue, AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { tokenRequest } from "../AuthConfig";
import { createCtx } from "../utils/Helpers";

export const [useAxiosContext, AxiosContextProvider] = createCtx<AxiosInstance>();

///<summary>
/// Offers an Axios instance that automatically updates the authentication token from MSAL when needeed
///</summary>
export const AxiosProvider = ({ children }: PropsWithChildren<unknown>) => {
	const { instance: msalInstance } = useMsal();

	const axiosInstance = useMemo(() => {
		const axiosInstance = axios.create({
			headers: {
				"Content-Type": "application/json",
			},
		});

		axiosInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig<any>) => {
			// get token from msal
			const account = msalInstance.getActiveAccount();

			if (account) {
				// TODO is it necessary to call acquireTokenSilent on every request?
				// console.log("Getting token silently");
				await msalInstance
					.acquireTokenSilent({
						...tokenRequest,
						account,
					})
					.then((response) => {
						if (response.accessToken) {
							const bearer: AxiosHeaderValue = `Bearer ${response.accessToken}`;
							config.headers.Authorization = bearer;
						}
					})
					.catch((error) => {
						if (error instanceof InteractionRequiredAuthError) {
							// clear cache and redirect to login
							// redirect logic implemented in AppContext.tsx
							// according to following link, logging in again should be enough, but seems like it was not, hence why we are logging out and redirecting
							// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#token-renewal
							msalInstance.logoutRedirect();
						} else {
							throw error;
						}
					});
			}
			return config;
		});
		// Add a response interceptor
		axiosInstance.interceptors.response.use(
			(response) => response,
			(error: AxiosError) => {
				if (error.response && error.response.data && (error.response.data as any).Message) {
					error.message = (error.response.data as any).Message; // set the error message to the server message
				}
				// Return the error object back to the calling code
				return Promise.reject(error);
			},
		);

		return axiosInstance;
	}, [msalInstance]);

	return <AxiosContextProvider value={axiosInstance}>{children}</AxiosContextProvider>;
};
