import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { User } from "../models/User";
import { createNumericHash } from "../utils/Helpers";

/**
 * Hook for storing data to localstorage. The data is stored in the localstorage with the key and the users username. This way the data is not shared between users.
 * @param key key for the localstorage
 * @param user user.username is used to make sure that the data is not shared between users
 * @param initialValue used if the key is not found in the localstorage
 * @returns an array with two elements: storedValue and setValue. storedValue is null until the user is set
 */
export function useLocalStorage<T>(key: string, user: User | undefined, initialValue?: T | null): [T, Dispatch<SetStateAction<T>>] {
	const prefixedKey = key + (user && user.username && user.username !== "" ? "-" + createNumericHash(user.username) : "");
	const [storedValue, setStoredValue] = useState<T>(() => {
		if (!user) return null;
		try {
			const item = window.localStorage.getItem(prefixedKey);
			return item ? JSON.parse(item) : item === "undefined" ? undefined : initialValue; // Null kuvaa sitä, että tietoa ei ole vielä haettu, undefined sitä, että localstoragessa ei ole sitä asetettuna
		} catch (error) {
			console.log(error);
			return initialValue;
		}
	});

	const setValue: Dispatch<SetStateAction<T>> = (value) => {
		if (!user) return;
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			// console.log("useLocalStorage.setValue", key, valueToStore);
			window.localStorage.setItem(prefixedKey, JSON.stringify(valueToStore));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (!user) return;
		const item = window.localStorage.getItem(prefixedKey);
		const value = item ? (item === "undefined" ? undefined : JSON.parse(item)) : initialValue;
		setStoredValue(value);
	}, [initialValue, prefixedKey, user]);

	return [storedValue, setValue];
}
