import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { useAppContext } from "./AppContext";
import { usePageLayoutContext } from "./PageLayoutProvider";
import { paths } from "./Routes";
import TopMenu from "./components/navigation/TopMenu";

interface Props {
	children: React.ReactNode;
}

export const PageLayout = ({ children }: Props) => {
	const { signIn } = useAppContext();
	const { t } = useTranslation();
	const { topMenuHeight, ref } = usePageLayoutContext();

	function UnauthorizedNavigateComponents() {
		const [timeSpentOnPage, setTimeSpentOnPage] = useState(0);
		const theme = useTheme();
		useEffect(() => {
			const timer = setInterval(() => {
				setTimeSpentOnPage((prevTime) => prevTime + 1);
			}, 1000);

			return () => {
				clearInterval(timer);
			};
		}, []);
		var urlPathname = window.location.pathname;
		if (urlPathname.toLowerCase() === paths.changepassword.path.toLowerCase()) return paths.changepassword.element;
		else if (urlPathname.toLowerCase() === paths.forgotpassword.path.toLowerCase()) return paths.forgotpassword.element;

		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					paddingTop: "40vh",
				}}
			>
				<Typography align="center" paddingY="1rem" fontSize={"2rem"}>
					{t("redirecting_to_login_page")}
				</Typography>
				<CircularProgress size={"5rem"} />
				{timeSpentOnPage > 3 && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							py: "1rem",
						}}
					>
						<Typography sx={{ color: theme.palette.info[900], fontSize: "1.5rem", textAlign: "center" }}>
							{t("redirect_taking_longer_than_usual")}
						</Typography>
						<Button size="large" variant="contained" onClick={signIn!} sx={{ my: "1rem" }}>
							{t("login")}
						</Button>
					</Box>
				)}
			</Box>
		);
	}

	return (
		<Box ref={ref} style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
			<TopMenu sx={{ displayPrint: "none" }} />
			<AuthenticatedTemplate>
				<Box sx={{ marginTop: topMenuHeight + 20 + "px" }}>{children}</Box>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<>
					<Box display="flex" justifyContent="center" minHeight="100vh">
						<UnauthorizedNavigateComponents />
					</Box>
				</>
			</UnauthenticatedTemplate>
		</Box>
	);
};
