// import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';
// import red from '@mui/material/colors/red';
import { createTheme, PaletteColor } from "@mui/material";
import { ColorPartial } from "@mui/material/styles/createPalette";
import chroma from "chroma-js";
import { Color } from "../utils/Colors";

interface ColorBW {
	0: Color;
	10: Color;
	20: Color;
	30: Color;
	40: Color;
	50: Color;
	60: Color;
	70: Color;
	80: Color;
	90: Color;
	100: Color;
}
type ColorPartialBW = Partial<ColorBW>;
interface PaletteColorBW extends ColorPartialBW {
	light: Color;
	main: Color;
	dark: Color;
	contrastText: Color;
}

// muutos, joka tarvitaan typescriptiä varten, että paletin väreissä on myös "shadet" mukana
// https://stackoverflow.com/a/67015020/963109
declare module "@mui/material/styles/createPalette" {
	interface PaletteColor extends ColorPartial {}
}

// creates Material ui color options from given color
// https://stackoverflow.com/questions/67013112/material-ui-how-to-access-all-palette-shades-inside-component
// https://github.com/KaindlJulian/material-palette-generator/blob/master/src/palette-generate.js
function createPaletteOptions(color: Color): PaletteColor {
	var newColor = {} as PaletteColor;
	newColor[50] = chroma.mix("white", color, 0.15).hex();
	newColor[100] = chroma.mix("white", color, 0.3).hex();
	newColor[200] = chroma.mix("white", color, 0.5).hex();
	newColor[300] = chroma.mix("white", color, 0.7).hex();
	newColor[400] = chroma.mix("white", color, 0.85).hex();
	newColor[500] = color;
	newColor.main = color;
	newColor[600] = chroma.mix("black", color, 0.85).hex();
	newColor[700] = chroma.mix("black", color, 0.7).hex();
	newColor[800] = chroma.mix("black", color, 0.5).hex();
	newColor[900] = chroma.mix("black", color, 0.3).hex();
	// HUOM! nämä vaalennukset on heitetty ihan näppituntumalla, eivät perustu mihinkään
	newColor["A100"] = chroma(color).saturate().brighten(2).hex();
	newColor["A200"] = chroma(color).saturate().brighten(1.5).hex();
	newColor["A400"] = chroma(color).saturate().brighten(0.7).hex();
	newColor["A700"] = chroma(color).saturate().brighten(0.1).hex();
	return newColor;
}

function createPaletteOptionsBW(color: Color): PaletteColorBW {
	var newColor = {} as PaletteColorBW;
	newColor[0] = chroma.mix("white", color, 0).hex() as Color;
	newColor[10] = chroma.mix("white", color, 0.3).hex() as Color;
	newColor[20] = chroma.mix("white", color, 0.5).hex() as Color;
	newColor[30] = chroma.mix("white", color, 0.7).hex() as Color;
	newColor[40] = chroma.mix("white", color, 0.85).hex() as Color;
	newColor[50] = color;
	newColor.main = color;
	newColor[60] = chroma.mix("black", color, 0.85).hex() as Color;
	newColor[70] = chroma.mix("black", color, 0.7).hex() as Color;
	newColor[80] = chroma.mix("black", color, 0.5).hex() as Color;
	newColor[90] = chroma.mix("black", color, 0.3).hex() as Color;
	newColor[100] = chroma.mix("black", color, 0).hex() as Color;
	return newColor;
}

//#region Nämä määrittelyt liittyvät oman värin lisäämiseen teemaan
declare module "@mui/material/styles" {
	interface Palette {
		neutral: PaletteColor;
		generic: PaletteColorBW;
		categoryColors: Color[];
		valueColors1: Color[];
		valueColors11: Color[];
		valueColors21: Color[];
		valueColors2: Color[];
		valueColors3: Color[];
		valueColors31: Color[];
		valueColors32: Color[];
	}
	interface PaletteOptions {
		neutral: PaletteColor;
		generic: PaletteColorBW;
		categoryColors: Color[];
		valueColors1: Color[];
		valueColors11: Color[];
		valueColors2: Color[];
		valueColors21: Color[];
		valueColors3: Color[];
		valueColors31: Color[];
		valueColors32: Color[];
	}
}
//#endregion

const ColorsWithLightVersions = (colors: Color[]) => {
	var result: Color[] = [];
	// original versions
	colors.forEach((color) => {
		result.push(color);
	});
	// light versions
	colors.forEach((color) => {
		result.push(chroma.mix("white", color, 0.5).name() as Color);
	});
	return result;
};

// Create a theme instance.
export const theme = createTheme({
	palette: {
		neutral: createPaletteOptions("#8484A0"),
		// HUOM! Leiskassa oli primary -värin eri sävyt laskettu väärin, siksi niitä asetetaan käsin paikoilleen
		primary: { ...createPaletteOptions("#6F4FD5"), "50": "#F5F1FA", "800": "#391586", "900": "#1D095A", main: "#391586" },
		secondary: createPaletteOptions("#FF505A"),
		success: createPaletteOptions("#53D93E"),
		info: createPaletteOptions("#0499F6"),
		warning: createPaletteOptions("#FF9600"),
		error: createPaletteOptions("#E20B06"),
		background: { default: "#F5F6FB" },
		generic: createPaletteOptionsBW("#F5F6FB"), // custom color,
		// ao. värit haettu Figmasta 23.11.2022 https://www.figma.com/file/a8dBhZoZEh9eikDw89bdyd/Eezynet-DS?node-id=2%3A8&t=U6jgAlublBEntdyr-0
		categoryColors: ColorsWithLightVersions(["#998BFF", "#85D3FF", "#339EFF", "#F97B96", "#FFA685", "#3EB7BF", "#FA82F2"]),
		valueColors1: ["#FF2D2E", "#FFFCF8", "#00BA29"], // Red to Green
		valueColors11: ["#FF7777", "#FFFCF8", "#59D274"], // Light red to white to light green
		valueColors2: ["#FF2D2E", "#FFFA9D", "#00BA29"], // Red to Yellow to Green
		valueColors21: ["#FF4243", "#FFFBB1", "#40CB5F"], // Light red to yellow to light green (updated 16.11.2023)
		valueColors3: ["#DE4580", "#FFFAFF", "#53BFFD"], // Pink to Blue
		valueColors31: ["#DE4580", "#d9c9eb", "#53BFFD"], // Pink to light blue to Blue
		valueColors32: ["#DE4580", "#D3D3D3", "#53BFFD"], // Pink to gray to Blue
	},
	typography: {
		h1: { fontSize: "3rem" }, // 48px
		h2: { fontSize: "2.5rem" }, // 40px
		h3: { fontSize: "2rem" }, // 32px
		h4: { fontSize: "1.75rem" }, // 28px
		h5: { fontSize: "1.5rem" }, // 24px
		h6: { fontSize: "1.25rem" }, // 20px
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					"@media print": {
						boxShadow: "none", // poistetaan varjo tulostettaessa
					},
				},
			},
		},
	},
});

const typographyOverrides = {
	body1: {
		fontSize: "1.15rem", // original 1rem
	},
	body2: {
		fontSize: "1rem", // original 0.875rem
	},
	subtitle1: {
		fontSize: "1.15rem", // original 1rem
	},
	subtitle2: {
		fontSize: "1rem", // original 0.875rem
	},
	button: {
		fontSize: "1rem", // original 0.875rem
	},
	caption: {
		fontSize: "0.85rem", // original 0.75rem
	},
};

export const reportTheme = createTheme(theme, {
	typography: typographyOverrides,
	components: {
		MuiTypography: {
			styleOverrides: typographyOverrides,
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: typographyOverrides.body2.fontSize,
				},
			},
		},
	},
});
export const tabularVisualizationTheme = createTheme(reportTheme, {
	components: {
		MuiTable: {
			styleOverrides: {
				stickyHeader: {
					backgroundColor: "inherit",
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					padding: "0.0rem 0.2rem",
					backgroundColor: "inherit",
				},
			},
		},
	},
});

// default style for select -components
export const selectStyle = {
	minWidth: 120,
	"& .MuiSelect-select": {
		fontSize: theme.typography.body2.fontSize,
		color: theme.palette.primary.main,
		fontWeight: 500,
	},

	"& .MuiSelect-multiple": {
		// se tyyli jota käytetään kun joku arvo on valittu
		fontSize: theme.typography.body2.fontSize,
		color: theme.palette.primary.main,
		fontWeight: 500,
	},

	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: theme.palette.primary[500],
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		borderColor: theme.palette.primary[500],
	},
};

// default style for menu items in select -components
export const menuItemStyle = {
	fontSize: theme.typography.body2.fontSize,
	color: theme.palette.neutral[500],
};

export type colorVariants = "error" | "secondary" | "primary" | "info" | "success" | "warning" | "inherit";
