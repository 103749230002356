import { Theme, ThemeProvider, createTheme } from "@mui/material";
import { useLocalizationContext } from "./LocalizationProvider";

type Props = {
	theme: Theme;
	children: React.ReactNode;
};

export const LocalizedThemeProvider = ({ theme, children }: Props) => {
	const { uiLocale, dateLocale } = useLocalizationContext();
	const localizedTheme = createTheme({ ...theme }, uiLocale, dateLocale);
	return <ThemeProvider theme={localizedTheme}>{children}</ThemeProvider>;
};
