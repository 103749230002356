export interface ErrorFallbackProps {
	error: Error;
	resetErrorBoundary?: (...args: Array<unknown>) => void;
}
export const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
	return (
		<div role="alert">
			<p>Error displaying the component 😢</p>
			<pre>{error.message}</pre>
			{resetErrorBoundary !== undefined && <button onClick={resetErrorBoundary}>Try again</button>}
		</div>
	);
};
