import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MaterialReactTable, type MRT_ColumnDef, type MRT_ColumnFiltersState, type MRT_PaginationState, type MRT_SortingState } from "material-react-table";
import { useGetErrors } from "../api/Errors";
import { Eezynet } from "../models/Eezynet";
import { exportData } from "../utils/Excel";

const Errors = () => {
	const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState<MRT_SortingState>([{ id: "id", desc: true }]);
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 0,
		pageSize: 20,
	});
	const { i18n } = useTranslation();

	const { data, isError, error: loadingError, isFetching, isLoading, refetch } = useGetErrors(columnFilters, globalFilter, pagination, sorting);

	const columns = useMemo<MRT_ColumnDef<Eezynet.SystemError>[]>( //TS helps with the autocomplete while writing columns
		() => [
			{ header: "Id", accessorKey: "id" },
			{
				header: "Date",
				accessorKey: "dateTime",
				accessorFn: (originalRow) =>
					new Intl.DateTimeFormat(i18n.language, { dateStyle: "short", timeStyle: "short" }).format(new Date(originalRow.dateTime)),
				Filter: ({ column }) => (
					<>
						<Box sx={{ display: "flex" }}>
							<DatePicker
								onChange={(newValue: Date | null) => {
									column.setFilterValue(newValue ? new Date(newValue.setHours(0, 0, 0, 0)) : null);
								}}
								slotProps={{
									textField: {
										// helperText: "Filter Mode: Less Than",
										sx: { minWidth: "120px" },
										variant: "standard",
									},
								}}
								value={column.getFilterValue() as Date | null}
							/>
							{/* TODO: tyhjentäminen ei toimi. FIltteri kyllä tyhjentyy, mutta päivämäärä ei muutu. */}
							{/* <IconButton
                                onClick={() => {
                                    column.setFilterValue(null);
                                }}
                                size="small"
                            >
                                <ClearIcon sx={{ opacity: "50%" }} />
                            </IconButton> */}
						</Box>
					</>
				),
			},
			{ header: "User Id", accessorKey: "userId" },
			{ header: "Customer Id", accessorKey: "customerId" },
			{ header: "Survey Id", accessorKey: "surveyId" },
			{ header: "Message", accessorKey: "message" },
			{ header: "Stacktrace", accessorKey: "stackTrace" },
		],
		[i18n.language],
	);
	return (
		<>
			<MaterialReactTable
				columns={columns}
				data={data?.data ?? []} //data is undefined on first render
				initialState={{
					showColumnFilters: true,
					columnVisibility: { stackTrace: false },
					density: "compact",
				}}
				manualFiltering
				manualPagination
				manualSorting
				muiToolbarAlertBannerProps={
					isError
						? {
								color: "error",
								children: "Error loading data" + (loadingError ? ": " + (loadingError.message ?? "Unknown error") : ""),
							}
						: undefined
				}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				renderTopToolbarCustomActions={() => (
					<>
						<Tooltip arrow title="Refresh Data">
							<IconButton onClick={() => refetch()}>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
						<Button variant="outlined" onClick={() => exportData("Errors", data?.data ?? [])}>
							Export to Excel
						</Button>
					</>
				)}
				rowCount={data?.totalRecords ?? 0}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isFetching,
					sorting,
				}}
				muiPaginationProps={{
					rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
				}}
			/>
			<ReactQueryDevtools initialIsOpen={false} />
		</>
	);
};

export default Errors;
