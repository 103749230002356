import Button from "@mui/material/Button";
import { useAppContext } from "../../AppContext";

export const SignInButton = () => {
	const app = useAppContext();
	return (
		<>
			<Button onClick={app.signIn!} color="inherit">
				Login
			</Button>
		</>
	);
};
