import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SelectEventSource, useAppContext } from "../AppContext";
import { paths } from "../Routes";
import { useGetCustomers } from "../api/Customers";
import { useGetSurveysByCustomerId } from "../api/Surveys";

/**
 * Tries to autoselect customer and survey. Redirects to customer selection page if unable to autoselect.
 * Call this inside authenticated template, to prevent unnesessary API calls before user is authenticated.
 * Side effect are: set selectedCustomerId and selectedSurveyId.
 *
 * @returns void
 */
export const useAutoSelectSurveyAndCustomer = () => {
	const { selectedCustomerId, setSelectedCustomerId, setSelectedSurveyId, selectedSurveyId } = useAppContext();
	const {
		data: surveysData,
		isLoading: surveyIsLoading,
		isFetching: surveyIsFetching,
	} = useGetSurveysByCustomerId({ customerId: selectedCustomerId, enabled: !!selectedCustomerId });
	const { data, isFetching: customerIsFetching, isLoading: customerIsLoading } = useGetCustomers({ pageSize: 500 });
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const location = useLocation();
	const navigate = useNavigate();

	const handleSelectCustomer = useCallback(() => {
		const shouldSelectCustomer = selectedCustomerId === undefined && selectedSurveyId === undefined;
		if (shouldSelectCustomer === false) return;
		if (customerIsFetching || customerIsLoading) return;

		const customerCount = data?.data.length ?? 0;
		const currentPath = location.pathname;
		const pathsToNavigateFrom = [paths.results.path, paths.analysis.path, paths.planning.path, paths.dashboard.path]; // if no customer selected redicrect to customer selection page
		const shouldRedirect = pathsToNavigateFrom.includes(currentPath);

		if (customerCount === 1) {
			const customerId = data?.data[0].id;
			setSelectedCustomerId(customerId, SelectEventSource.NotSet);
			return;
		}
		if (customerCount > 1 && shouldRedirect) {
			navigate(paths.customers.path);
			enqueueSnackbar(t("select_customer_to_continue"), { variant: "info" });
			return;
		}

		// what to do when no customers?
	}, [
		data,
		selectedCustomerId,
		setSelectedCustomerId,
		selectedSurveyId,
		navigate,
		location.pathname,
		enqueueSnackbar,
		customerIsFetching,
		customerIsLoading,
		t,
	]);

	const handleSelectSurvey = useCallback(() => {
		if (surveyIsLoading || surveyIsFetching) return;
		const surveys = surveysData?.data;
		const latestSurveyId = surveys?.reduce((prevSurvey, currentSurvey) => {
			const currentDate = currentSurvey.date ? new Date(currentSurvey.date) : null;
			const prevDate = prevSurvey?.date ? new Date(prevSurvey.date) : null;
			if (!prevSurvey || (currentDate && prevDate && currentDate > prevDate)) {
				return currentSurvey;
			}
			return prevSurvey;
		}).id;

		if (latestSurveyId && selectedSurveyId === undefined) {
			setSelectedSurveyId(latestSurveyId, selectedCustomerId, SelectEventSource.NotSet);
		}
	}, [selectedSurveyId, setSelectedSurveyId, selectedCustomerId, surveysData, surveyIsLoading, surveyIsFetching]);

	useEffect(() => {
		handleSelectSurvey();
		handleSelectCustomer();
	}, [handleSelectSurvey, handleSelectCustomer]);
};
