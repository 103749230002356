import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosInstance } from "axios";
import { useAppContext } from "../AppContext";
import { ApiRootUrl, apiRoutes } from "../Routes";
import { Eezynet } from "../models/Eezynet";
import { RespondentGroupService } from "../services/RespondentGroupService";
import { useAxiosContext } from "./AxiosProvider";

const respondentGroupBatchAmount = 100;

// Function to get respondent group hash in imperative way
export const getRespondentGroupHash = (axiosInstance: AxiosInstance, respondentGroup?: Eezynet.RespondentGroup) => {
	const URL = apiRoutes.getRespondentGroupHash;
	const params = respondentGroup;
	return axiosInstance.post<string>(ApiRootUrl + URL, params).then((response) => {
		return response.data;
	});
};

// Function to get multiple respondent group hashes in imperative way
export const getRespondentGroupsHashes = (axiosInstance: AxiosInstance, respondentGroups: Eezynet.ExtendedRespondentGroup[]) => {
	const URL = apiRoutes.getRespondentGroupsHashes;
	const params = respondentGroups;
	return axiosInstance.post<{ externalId: string; hash: string }[]>(ApiRootUrl + URL, params).then((response) => {
		return response.data;
	});
};

// Function to get respondent group hash in declarative way (hook)
export const useGetRespondentGroupHash = (respondentGroup?: Eezynet.RespondentGroup, enabled: boolean = true) => {
	const axiosInstance = useAxiosContext();
	const URL = apiRoutes.getRespondentGroupHash;
	const params = respondentGroup;
	return useQuery<string, AxiosError>({
		queryKey: [URL, params],
		queryFn: async () => {
			const { data } = await axiosInstance.post<string>(ApiRootUrl + URL, params);
			return data;
		},
		enabled: enabled && !!respondentGroup,
	});
};

// Function to get respondent group information (hash, respondetn count and name) in imperative way
export const getRespondentGroupInformationSingle = (
	axiosInstance: AxiosInstance,
	respondentGroup: Eezynet.RespondentGroup,
	selectedSurveyLanguage?: string,
	currentSurveyId?: number,
) => {
	const URL = apiRoutes.getRespondentGroupInformation;
	// Valitettavasti tuo naming convention ei siirtynyt respondet groupin mukana (en ymmärrä miksi api ei osannut deserialisoida sitä, joten siirrään tiedon erikseen
	const params = {
		respondentGroups: [respondentGroup],
		namingConventions: respondentGroup?.namingConventions,
		LanguageCulture: selectedSurveyLanguage,
		CurrentSurveyId: currentSurveyId
	};
	return axiosInstance.post<Eezynet.RespondentGroup[]>(ApiRootUrl + URL, params).then((response) => {
		const singleRg = response.data && response.data.length > 0 ? response.data[0] : undefined;
		return new Eezynet.RespondentGroup({
			...respondentGroup,
			respondentCount: singleRg?.respondentCount,
			hash: singleRg?.hash,
			name: singleRg?.name,
			shortName: singleRg?.shortName,
		});
	});
};

export const getRespondentGroupInformation = (axiosInstance: AxiosInstance, respondentGroups: Eezynet.RespondentGroup[], selectedSurveyLanguage?: string, currentSurveyId?: number) => {

	const URL = apiRoutes.getRespondentGroupInformation;

	const batchedArrays = batchArray(Object.values(respondentGroups), respondentGroupBatchAmount);
	const promises = batchedArrays.map(chunk => 
		axiosInstance.post<Eezynet.RespondentGroup[]>(ApiRootUrl + URL, { RespondentGroups: chunk, LanguageCulture: selectedSurveyLanguage, CurrentSurveyId: currentSurveyId })
			.then(response => response.data)
	);

	return Promise.all(promises)
		.then(allResponses => {
			const flattenedResponses = allResponses.flat();
			return flattenedResponses;
		});
};

function batchArray(respondentGroups: Eezynet.RespondentGroup[], size: number) {
	const batches = [];
	for (let i = 0; i < respondentGroups.length; i += size) {
		batches.push(respondentGroups.slice(i, i + size));
	}
	return batches;
}

// Function to get respondent group information (hash, respondetn count and name) in declarative way (hook)
export const useGetRespondentGroupInformationSingle = (respondentGroup?: Eezynet.RespondentGroup, currentSurveyId?: number, enabled: boolean = true) => {
	const { selectedSurveyLanguage } = useAppContext();
	const axiosInstance = useAxiosContext();
	const URL = apiRoutes.getRespondentGroupInformation;
	// Valitettavasti tuo naming convention ei siirtynyt respondet groupin mukana (en ymmärrä miksi api ei osannut deserialisoida sitä, joten siirrään tiedon erikseen
	const params = {
		RespondentGroups: [respondentGroup],
		namingConventions: respondentGroup?.namingConventions,
		LanguageCulture: selectedSurveyLanguage,
		CurrentSurveyId: currentSurveyId,
	};
	return useQuery<Eezynet.RespondentGroup, AxiosError>({
		queryKey: [URL, params],
		queryFn: async () => {
			const { data } = await axiosInstance.post<Eezynet.RespondentGroup[]>(ApiRootUrl + URL, params);
			const singleRg = data && data.length > 0 ? data[0] : undefined;

			return {
				...respondentGroup,
				respondentCount: singleRg?.respondentCount,
				hash: singleRg?.hash,
				name: singleRg?.name,
				shortName: singleRg?.shortName,
			} as Eezynet.RespondentGroup;
		},
		enabled: enabled && !!respondentGroup,
	});
};

// make a async function loadRespondentGroups that gets parameters: respondentGroups: Eezynet.RespondentGroup[], axiosInstance: AxiosInstance
// it calls async function getRespondentGroupInformation(axiosInstance, respondentgroup) for each respondent group
// when that function is ready, it set the hash, name and respondentCount to the respondent group and returns it
// then it calls the function for the comparison group if it exists and do the same
// then it returns the respondent groups
export const loadRespondentGroups = async (
	respondentGroups: Eezynet.RespondentGroup[],
	rgService: RespondentGroupService,
	LanguageCulture?: string,
	selectedSurveyId?: number
): Promise<Eezynet.RespondentGroup[]> => {
	const rgs = await Promise.all(
		respondentGroups.map(async (rg) => {
			rg = await rgService.getRespondentGroup({ respondentGroup: rg, selectedSurveyLanguage: LanguageCulture, selectedSurveyId: selectedSurveyId });
			if (rg.comparisonGroup) {
				rg.comparisonGroup = await rgService.getRespondentGroup({
					respondentGroup: rg.comparisonGroup,
					selectedSurveyLanguage: LanguageCulture,
					selectedSurveyId: selectedSurveyId,
				});
			}
			return rg;
		}),
	);
	return rgs;
};
