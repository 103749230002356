import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, FormControl, FormLabel, LinearProgress, TextField, Typography } from "@mui/material";
import { paths } from "../Routes";
import { GetNewPasswordEmail } from "../api/Authentication";
import { useAxiosContext } from "../api/AxiosProvider";
import ProgressLine from "../components/ProgressLine";
import { isValidEmail } from "../utils/Helpers";

export const ForgotPassword = () => {
	const { t } = useTranslation();
	const [email, setEmail] = useState<string>("");
	const axiosInstance = useAxiosContext();
	const navigate = useNavigate();
	const [error, setError] = useState<string | undefined>();
	const [showRequsetSent, setShowRequestSent] = useState<boolean>(false);
	const [disableButton, setDisableButton] = useState<boolean>(false);
	const delayBeforeRedirect = 2;

	const handleRequestNewPassword = () => {
		if (isValidEmail(email)) {
			setDisableButton(true);
			GetNewPasswordEmail(axiosInstance, email)
				.then((response) => {
					setShowRequestSent(true);
					setTimeout(() => {
						navigate(paths.home.path);
						window.location.reload();
					}, delayBeforeRedirect * 1000);
				})
				.catch((e) => {
					setError(e.message);
					setDisableButton(false);
					// If necessary, error handling/logging can be done here.
					// this "then/catch" purpose is to prevent showing error on the website page
				});
		}
	};

	return (
		<Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
			{/* Create a Box that has flex so that the items are on top of eachothers with padding of 2 */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					// add pagging between items
					"& > :not(style) + :not(style)": {
						marginTop: 2,
					},
				}}
			>
				<Typography variant="h1">{t("request_password_reset")}</Typography>
				<FormControl>
					<FormLabel>{t("text_email")}</FormLabel>
					<TextField
						id="email"
						type="email"
						error={!!email && !isValidEmail(email)}
						value={email}
						autoComplete="off"
						onChange={(e) => setEmail(e.target.value)}
					/>
				</FormControl>
				<Button onClick={handleRequestNewPassword} disabled={!isValidEmail(email) || !email || showRequsetSent || disableButton} variant="contained">
					{t("send_request")}
				</Button>
				{disableButton && !showRequsetSent && <LinearProgress sx={{ width: "100px" }} />}
				{showRequsetSent && (
					<Alert severity="success">
						{t("request_sent")}
						<ProgressLine seconds={delayBeforeRedirect} sx={{ width: "100px" }} />
					</Alert>
				)}
				{error && <Alert severity="error">{error}</Alert>}
			</Box>
		</Box>
	);
};
export default ForgotPassword;
