import { keepPreviousData, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { ApiRootUrl, apiRoutes } from "../Routes";
import { Eezynet } from "../models/Eezynet";
import { pathToUrl } from "../utils/pathToUrl";
import { useAxiosContext } from "./AxiosProvider";

export const useGetErrorsInfinite = ({ pageSize = 100, enabled = true }: { pageSize: number; enabled?: boolean }) => {
	const axiosInstance = useAxiosContext();
	return useInfiniteQuery<Eezynet.PagedResult<Eezynet.SystemError>, AxiosError>({
		queryKey: ["getErrors"],
		initialPageParam: 0,
		queryFn: async ({ pageParam }) => {
			const pageNumber = typeof pageParam === "number" ? pageParam : 0;
			const URL = ApiRootUrl + pathToUrl(apiRoutes.getErrors, { pageNumber, pageSize: pageSize });
			const { data } = await axiosInstance.post<Eezynet.PagedResult<Eezynet.SystemError>>(URL, {});
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage.pageNumber < lastPage.totalPages ? lastPage.pageNumber + 1 : null;
		},
		enabled,
	});
};

export function useGetErrors(columnFilters: MRT_ColumnFiltersState, searchTerm: string, pagination: MRT_PaginationState, sorting: MRT_SortingState) {
	const axiosInstance = useAxiosContext();
	return useQuery<Eezynet.PagedResult<Eezynet.SystemError>, AxiosError>({
		queryKey: [
			"errors",
			columnFilters,
			searchTerm,
			pagination.pageIndex,
			pagination.pageSize,
			sorting, //refetch when sorting changes
		],
		queryFn: async () => {
			const URL =
				ApiRootUrl +
				pathToUrl(
					apiRoutes.getErrors,
					{
						pageNumber: pagination.pageIndex + 1,
						pageSize: pagination.pageSize,
						filters: JSON.stringify(columnFilters ?? []),
						sorting: JSON.stringify(sorting ?? []),
						searchTerm: !!searchTerm ? searchTerm : undefined, // ei välitetä tyhjää hakusanaa
					},
					true,
				);
			const { data } = await axiosInstance.post<Eezynet.PagedResult<Eezynet.SystemError>>(URL, {});
			return data;
		},
		placeholderData: keepPreviousData,
	});
}
