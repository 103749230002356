import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, Grid, LinearProgress, Typography } from "@mui/material";
import { useAppContext } from "../AppContext";
import { paths } from "../Routes";
import { useGetCustomers } from "../api/Customers";
import { useGetSurveysByCustomerId } from "../api/Surveys";
import LoggedInName from "../components/authentication/LoggedInName";
import { Eezynet } from "../models/Eezynet";
import { UserType } from "../models/User";
import { pathToUrl } from "../utils/pathToUrl";

const Home = () => {
	const { t } = useTranslation();
	const { user, isDebugging } = useAppContext();
	const navigate = useNavigate();
	//  find the latest survey and navigate to it

	const [currentCustomer] = useState<Eezynet.Customer | undefined>(undefined);
	const [getCustomersEnabled, setGetCustomersEnabled] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const { data: customersData } = useGetCustomers({ enabled: getCustomersEnabled });
	const { data: surveysData } = useGetSurveysByCustomerId({ customerId: currentCustomer?.id, enabled: !!currentCustomer });

	const hasAllreadyNavigated = useRef(false);

	const navigationHelper = useCallback(
		(url: string, reason?: string) => {
			if (hasAllreadyNavigated.current) return;
			hasAllreadyNavigated.current = true;
			if (isDebugging) console.log(`User redirected to => ${url}, reason: ${reason ?? "unknown reason"}`);
			navigate(url);
		},
		[isDebugging, navigate],
	);

	// eteenpäinsiirtymisen logiikka
	useEffect(() => {
		if (!user) return; // käyttäjän tiedot tulevat asynkroonisesti

		// jos sisäinen käyttäjä:
		// - näytetään aina customers -sivu  (vai pitäisikö siirtyä suoraan results -sivulle?)
		if (user?.userType === UserType.Internal || user?.userType === UserType.Admin) {
			navigationHelper(paths.customers.path, "internal user");
			return;
		}

		// jos ulkoinen käyttäjä:
		// jos tiedossa on viimeisin tutkimus:
		// - siirrytään aina results -sivulle, jossa katsotaan onko käyttäjällä oikeus nähdä ko. tutkimus
		if (user?.currentSurveyId) {
			navigationHelper(pathToUrl(paths.resultsBySurveyId.path, { id: user?.currentSurveyId }), "external user, last survey is known");
			return;
		}
		// jos ei tiedossa viimeisintä tutkimusta:
		// - haketaan tämän käyttäjän asiakkaat
		setGetCustomersEnabled(true);

		if (customersData) {
			//  - jos asiakkaita on vain yksi, etsitään tämän asiakkaan viimeisin tutkimus johon on oikeudet ja siirrytään sinne
			if (customersData.data.length === 1) {
				if (!surveysData) return;
				// take the first survey and navigate to it
				const survey = surveysData.data[0];
				if (!survey) return;
				navigationHelper(pathToUrl(paths.resultsBySurveyId.path, { id: survey.id }), "external user, last survey is unknown, only one customer");
				return;
			} else {
				// jos ei yhtään tai enemmän kun yksi tutkimus
				navigationHelper(paths.customers.path, "external user, last survey is unknown, zero or more than one customer(s)");
				return;
			}
		}
		setIsLoading(false);

		//  - jos asiakkaita on useampi, siirrytään Customers -sivulle
	}, [user, navigate, customersData, surveysData, navigationHelper]);

	return (
		<Container maxWidth="lg">
			<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: "80vh" }}>
				<Grid item xs={3}>
					<Typography variant="h1" sx={{ my: 3 }}>
						{t("text_welcome", { name: LoggedInName({ onlyFirstname: true }) })}!
					</Typography>
					{isLoading ? (
						<>
							<Typography variant="body1">Loading...</Typography>
							<LinearProgress />
						</>
					) : (
						<>{/* <Alert severity="error">No surveys found</Alert> */}</>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};
export default Home;
