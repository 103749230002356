import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { ApiRootUrl, apiRoutes } from "../Routes";
import { Eezynet } from "../models/Eezynet";
import { User } from "../models/User";
import { pathToUrl } from "../utils/pathToUrl";
import { useAxiosContext } from "./AxiosProvider";

export function GetUserInfo_ReactQuery() {
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.getUserInfo);
	return useQuery({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<User>(ApiRootUrl + URL);
			return data;
		},
	});
}

// Function to GetUserInfo in imperative way
export const GetUserInfo = (axiosInstance: AxiosInstance) => {
	const URL = apiRoutes.getUserInfo;
	return axiosInstance.get<User>(ApiRootUrl + URL).then((result) => {
		return result;
	});
};

// Send a request for a new password. Receives an email from Cixtranet for changing it
export const GetNewPasswordEmail = async (axiosInstance: AxiosInstance, email: string) => {
	const URL = pathToUrl(apiRoutes.getNewPasswordEmail, { email: email });
	return axiosInstance.post<boolean>(ApiRootUrl + URL, email);
};

// Send and update a new password.
export const UpdateCixtranetPassword = (axiosInstance: AxiosInstance, request: Eezynet.ChangePasswordRequest) => {
	const URL = pathToUrl(apiRoutes.updateCixtranetPassword, { request: request });
	return axiosInstance.post<boolean>(ApiRootUrl + URL, request);
};
