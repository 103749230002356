import React, { useCallback, useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { OnRefChangeType } from "react-resize-detector/build/types/types";
import { useMediaQuery, useTheme } from "@mui/material";
import { createCtx } from "./utils/Helpers";
import { usePrevious } from "./utils/UseEffectHelper";

type MaxCharBreakpoints = {
	[key: number]: number;
};

type PageLayoutProviderType = {
	drawerOpen: boolean;
	topMenuHeight: number;
	currentDrawerWidth: number;
	drawerOpenWidth: number;
	mobileDrawerWidth: number;
	isMobile: boolean;
	setDrawerOpenWidth: (width: number) => void;
	drawerClosedWidth: number;
	setDrawerClosedWidth: (width: number) => void;
	handleDrawerToggle: () => void;
	setCurrentDrawerWidth: (width: number) => void;
	currentAppWidth: number | undefined;
	currentScreenWidth: number;
	currentLabelsMaxCharactersLength: number;

	ref: OnRefChangeType<HTMLDListElement>;
};

export const [usePageLayoutContext, PageLayoutContextProvider] = createCtx<PageLayoutProviderType>();
export const PageLayoutProvider = ({ children }: React.PropsWithChildren) => {
	const mobileDrawerWidth = 256;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md")); // match breakpoint of top menu
	const isMobilePrevious: boolean = usePrevious(isMobile, isMobile);
	const [drawerOpen, setDrawerOpen] = React.useState(!isMobile); // default to open on desktop but not on mobile
	const [previousStatus, setPreviousStatus] = React.useState(drawerOpen); // save previous open state when switching between mobile and desktop
	const [drawerOpenWidth, setDrawerOpenWidth] = React.useState(256);
	const [drawerClosedWidth, setDrawerClosedWidth] = React.useState(60);
	const { width: currentAppWidth, ref } = useResizeDetector(); // tracks screen width based on component size
	const [currentScreenWidth, setCurrentScreenWidth] = useState<number>(window.innerWidth); // tracks screen width based on window size
	const [currentDrawerWidth, setCurrentDrawerWidth] = React.useState(drawerOpenWidth);
	const topMenuHeight = 64;
	const [currentLabelsMaxCharactersLength, setCurrentLabelsMaxCharacterLength] = useState<number>(500);

	const handleSwitchingToMobile = React.useCallback(() => {
		// close drawer when switching to mobile
		setDrawerOpen(false);
		// drawer width is fixed on mobile
		setCurrentDrawerWidth(0);
		setPreviousStatus(drawerOpen);
	}, [drawerOpen]);

	const handleSwitchingToDesktop = React.useCallback(() => {
		// open drawer to collapsed / full state depending on how it was before
		setDrawerOpen(previousStatus);
		setCurrentDrawerWidth(previousStatus ? drawerOpenWidth : drawerClosedWidth);
	}, [previousStatus, drawerOpenWidth, drawerClosedWidth]);

	React.useEffect(() => {
		// No need to do anything if the screen breakpoint hasn't changed
		if (isMobile !== isMobilePrevious) {
			if (isMobile) handleSwitchingToMobile();
			if (!isMobile) handleSwitchingToDesktop();
		}
	}, [isMobile, handleSwitchingToMobile, handleSwitchingToDesktop, isMobilePrevious]);

	const handleDrawerToggle = () => {
		if (!isMobile) drawerOpen ? setCurrentDrawerWidth(drawerClosedWidth) : setCurrentDrawerWidth(drawerOpenWidth);
		setDrawerOpen(!drawerOpen);
	};

	const getMaxCharacters = useCallback(() => {
		// a bit hacky way of fixing the max amount of characters in the text fields, based on the window width
		const maxCharacters: MaxCharBreakpoints = { 400: 30, 450: 35, 500: 40, 550: 45, 600: 50, 800: 70, 1200: 100 };
		const breakpoints: number[] = Object.keys(maxCharacters).map(Number);
		const aboveBreakpoint = breakpoints.filter((bp) => bp <= currentScreenWidth).pop() ?? 400;
		return maxCharacters[aboveBreakpoint];
	}, [currentScreenWidth]);

	const handleWindowResize = useCallback(() => {
		if (window.innerWidth !== currentScreenWidth) {
			setCurrentScreenWidth(window.innerWidth);
		}
		const newMaxCharacters = getMaxCharacters();
		if (newMaxCharacters !== currentLabelsMaxCharactersLength) {
			setCurrentLabelsMaxCharacterLength(newMaxCharacters);
		}
	}, [currentScreenWidth, currentLabelsMaxCharactersLength, getMaxCharacters]);

	useEffect(() => {
		handleWindowResize();
		window.addEventListener("resize", handleWindowResize);
		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, [handleWindowResize]);

	const value = {
		drawerOpen,
		drawerOpenWidth,
		mobileDrawerWidth,
		isMobile,
		setDrawerOpenWidth,
		drawerClosedWidth,
		setDrawerClosedWidth,
		currentDrawerWidth,
		setCurrentDrawerWidth,
		topMenuHeight,
		handleDrawerToggle,
		currentAppWidth,
		currentScreenWidth,
		currentLabelsMaxCharactersLength,
		ref,
		children,
	};

	return <PageLayoutContextProvider value={value}>{children}</PageLayoutContextProvider>;
};
