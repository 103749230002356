import {
	Localization,
	fiFI as _fiFI,
	svSE as _svSE,
	enUS as _enUS,
	daDK as _daDK,
	nlNL as _nlNL,
	deDE as _deDE,
	nbNO as _nbNO,
	ruRU as _ruRU,
	esES as _esES,
} from "@mui/material/locale";
import { LocalizationProvider as BaseLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Locale, setDefaultOptions } from "date-fns";
import { enUS, enGB, fi, sv, da, nl, de, nn, ru, es, nb } from "date-fns/locale";
import { useAppContext } from "./AppContext";
import { createCtx } from "./utils/Helpers";

type Locales = {
	dateLocale: Locale;
	uiLocale: Localization;
};

const LOCALE_MAP: Record<string, Locales> = {
	fi: { dateLocale: fi, uiLocale: _fiFI },
	"fi-FI": { dateLocale: fi, uiLocale: _fiFI },
	sv: { dateLocale: sv, uiLocale: _svSE },
	"sv-SE": { dateLocale: sv, uiLocale: _svSE },
	en: { dateLocale: enUS, uiLocale: _enUS },
	"en-US": { dateLocale: enUS, uiLocale: _enUS },
	"en-GB": { dateLocale: enGB, uiLocale: _enUS },
	da: { dateLocale: da, uiLocale: _daDK },
	"da-DK": { dateLocale: da, uiLocale: _daDK },
	nl: { dateLocale: nl, uiLocale: _nlNL },
	"nl-NL": { dateLocale: nl, uiLocale: _nlNL },
	de: { dateLocale: de, uiLocale: _deDE },
	"de-DE": { dateLocale: de, uiLocale: _deDE },
	nn: { dateLocale: nn, uiLocale: _nbNO },
	nb: { dateLocale: nb, uiLocale: _nbNO },
	"no-NO": { dateLocale: nn, uiLocale: _nbNO },
	"no-NB": { dateLocale: nb, uiLocale: _nbNO },
	ru: { dateLocale: ru, uiLocale: _ruRU },
	"ru-RU": { dateLocale: ru, uiLocale: _ruRU },
	es: { dateLocale: es, uiLocale: _esES },
	"es-ES": { dateLocale: es, uiLocale: _esES },
};
type LocalizationContextType = {
	dateLocale: Locale;
	uiLocale: Localization;
};
export const [useLocalizationContext, LocalizationContextProvider] = createCtx<LocalizationContextType>();

export function LocalizationProvider({ children }: { children: React.ReactNode }) {
	const { selectedUiLanguage } = useAppContext();
	const locale = LOCALE_MAP[selectedUiLanguage || "en-US"] || enUS;
	setDefaultOptions({ locale: locale.dateLocale });
	return (
		<LocalizationContextProvider value={{ dateLocale: locale.dateLocale, uiLocale: locale.uiLocale }}>
			<BaseLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale.dateLocale}>
				{children}
			</BaseLocalizationProvider>
		</LocalizationContextProvider>
	);
}
