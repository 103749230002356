import { useEffect, useState } from "react";
import { SxProps, Theme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * A component that displays a linear progress bar that fills up over a specified number of seconds.
 * @param seconds The number of seconds it takes for the progress bar to fill up completely.
 * @param sx Optional styling props for the LinearProgress component.
 * @returns A LinearProgress component that fills up over the specified number of seconds.
 */
const ProgressLine = ({ seconds, sx }: { seconds: number; sx?: SxProps<Theme> }) => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		// Calculate the increment value for each interval
		const incrementValue = 100 / (seconds * 10);

		// Set an interval to update the progress
		const interval = setInterval(() => {
			setProgress((prevProgress) => {
				if (prevProgress >= 100) {
					clearInterval(interval);
					return 100;
				}
				return prevProgress + incrementValue;
			});
		}, 100); // Update every 100ms for smoother animation

		// Clear the interval when the component is unmounted
		return () => {
			clearInterval(interval);
		};
	}, [seconds]);

	return <LinearProgress variant="determinate" value={progress} sx={sx} />;
};

export default ProgressLine;
