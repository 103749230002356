import { compile } from "path-to-regexp";

// Returns a function for transforming parameters into a valid path.
// https://www.npmjs.com/package/path-to-regexp
type Params = {
	[key: string]: string | number | boolean | undefined | object;
};

export const pathToUrl = (path: string, params: Params = {}, allowMissingParams: boolean = false, enabled: boolean = true) => {
	if (!enabled) {
		return path;
	}
	// Extract the base path and query parameters. base path is evetything before the first ? and query string is everything after. note that there can be multiple ? in the path
	const basePath = path.split("\\?")[0];
	const queryString = path.replace(basePath, "").substring(2); // poistetaan lopusta \\?

	// Convert all params to strings, filtering out null and undefined for path parameters
	const pathParams: { [key: string]: string } = {};
	const queryParams: { [key: string]: string } = {};

	// Check for missing required path parameters
	const requiredPathParams = (basePath.match(/:\w+/g) || []).map((param) => param.slice(1));
	for (const requiredParam of requiredPathParams) {
		if (params[requiredParam] === undefined || params[requiredParam] === null) {
			console.error(`Missing required path parameter: ${requiredParam}`);
			return path; // Return the original path
		} else {
			pathParams[requiredParam] = params[requiredParam]!.toString();
		}
	}

	for (const key in params) {
		const value = params[key];
		if (value !== undefined && value !== null && !basePath.includes(`:${key}`)) {
			queryParams[key] = encodeURIComponent(value.toString());
		}
	}

	// Compile the base path with path parameters
	let compiledPath;
	try {
		compiledPath = compile(basePath)(pathParams);
	} catch (error) {
		console.error(`Error compiling path: ${error instanceof Error ? error.message : error}`);
		return path; // Return the original path
	}

	if (!queryString) {
		return compiledPath;
	}

	// Parse the query string into individual parameter names
	const queryKeys = queryString.split("&").map((part) => part.split("=")[0]);

	// Construct the query string with the remaining cleaned params
	const compiledQueryString = queryKeys
		.map((key) => {
			if (queryParams[key] !== undefined) {
				return `${key}=${queryParams[key]}`;
			} else if (!allowMissingParams) {
				console.error(`Missing required query parameter: ${key}`);
				return null;
			}
			return null;
		})
		.filter((part) => part !== null)
		.join("&");

	return compiledQueryString ? `${compiledPath}?${compiledQueryString}` : compiledPath;
};
