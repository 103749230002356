import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./styles/theme";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { SnackbarProvider } from "notistack";
import "./App.css";
import { AppContext } from "./AppContext";
import { LocalizationProvider } from "./LocalizationProvider";
import { LocalizedThemeProvider } from "./LocalizedThemeProvider";
import { PageLayout } from "./PageLayout";
import { PageLayoutProvider } from "./PageLayoutProvider";
import { PageRoutes } from "./Routes";
import { AxiosProvider } from "./api/AxiosProvider";
import { RespondentGroupServiceProvider } from "./services/RespondentGroupService";
import "./translations/i18n";

interface Props {
	pca: IPublicClientApplication;
}

function App({ pca }: Props) {
	return (
		<>
			{/* CssBaseline on asetettu esimerkeissä app:n sisällä */}
			<CssBaseline />
			<MsalProvider instance={pca}>
				<AxiosProvider>
					<AppContext>
						<RespondentGroupServiceProvider>
							<LocalizationProvider>
								<LocalizedThemeProvider theme={theme}>
									<SnackbarProvider maxSnack={3}>
										<PageLayoutProvider>
											<PageLayout>
												<PageRoutes />
											</PageLayout>
										</PageLayoutProvider>
									</SnackbarProvider>
								</LocalizedThemeProvider>
							</LocalizationProvider>
						</RespondentGroupServiceProvider>
					</AppContext>
				</AxiosProvider>
			</MsalProvider>
		</>
	);
}

export default App;
