import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { getName } from "../../utils/Helpers";

export default function LoggedInName({ onlyFirstname = false, onlyInitials = false }) {
	const { accounts } = useMsal();
	const account = useAccount(accounts[0] || {});
	const [name, setName] = useState(account?.name ?? "");

	useEffect(() => {
		setName(getName({ onlyFirstname, onlyInitials, name: account?.name }));
	}, [account, onlyFirstname, onlyInitials]);
	return name ? name : null;
}
