import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError, AxiosInstance } from "axios";
import { ApiRootUrl, apiRoutes } from "../Routes";
import { Eezynet } from "../models/Eezynet";
import { pathToUrl } from "../utils/pathToUrl";
import { useAxiosContext } from "./AxiosProvider";

export function useGetUILanguages() {
	const axiosInstance = useAxiosContext();
	const URL = apiRoutes.getUILanguages;
	return useQuery<Eezynet.Language[], AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.Language[]>(ApiRootUrl + URL);
			return data;
		},
	});
}

export function useGetSystemTranslations(culture?: string) {
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.getAllFullTranslations, { culture: culture }, true);
	return useQuery<Eezynet.FullTranslation[], AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.FullTranslation[]>(ApiRootUrl + URL);
			return data;
		},
	});
}

export const getAllEditableSystemTranslations = (axiosInstance: AxiosInstance) => {
	const URL = apiRoutes.getAllFullTranslations;
	return axiosInstance.get<Eezynet.FullTranslation[]>(ApiRootUrl + URL).then((response) => {
		return response.data;
	});
};

export const SaveSystemTranslations = (axiosInstance: AxiosInstance, systemtranslations: Eezynet.FullTranslation[]) => {
	const URL = apiRoutes.saveSystemTranslation;
	axiosInstance.post(ApiRootUrl + URL, systemtranslations);
};

export const useGetSurveyTranslationKeys = (surveyId?: number) => {
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.getSurveyTranslationKeys, { surveyid: surveyId });
	return useQuery<string[], AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<string[]>(ApiRootUrl + URL);
			return data.sort();
		},
		enabled: surveyId !== undefined,
	});
};

export const useGetSurveyTranslations = (surveyId?: number, culture?: string): UseQueryResult<Eezynet.FullTranslation[], AxiosError> => {
	// TODO: API puuttuu vielä siksi tämän oikean toteutuksen tilalle palautetaan placeholder data
	// const URL = pathToUrl(apiRoutes.getSurveyTranslations, { surveyid: surveyId, culture: culture });
	// return useQuery<Eezynet.FullTranslation[], AxiosError>({
	// 	queryKey: [URL],
	// 	queryFn: async () => {
	// 		const { data } = await axiosInstance.get<Eezynet.FullTranslation[]>(ApiRootUrl + URL);
	// 		return data.sort();
	// 	},
	// 	enabled: surveyId !== undefined && culture !== undefined,
	// });

	// Fetch the translation keys using the existing hook
	const translationKeysQuery = useGetSurveyTranslationKeys(surveyId);

	return useQuery<Eezynet.FullTranslation[], AxiosError>({
		queryKey: ["getSurveyTranslations", surveyId, culture],
		queryFn: async () => {
			const translationKeys = translationKeysQuery.data;

			if (!translationKeys) {
				return [];
			}

			// Map the translation keys to FullTranslation objects
			const translations = translationKeys.map((key) => {
				return {
					id: 0, // Placeholder ID
					translationKey: key,
					languageId: 0, // Set this based on the culture or other logic
					text: "", // Empty text as per your requirement
				};
			});

			return translations;
		},
		enabled: translationKeysQuery.isSuccess && !!surveyId,
	});
};

export const useGetSurveyTranslation = (translationKey: string | undefined, surveyId?: number) => {
	const axiosInstance = useAxiosContext();
	const URL =
		translationKey !== undefined
			? pathToUrl(apiRoutes.getSurveyTranslation, { surveyid: surveyId, translationkey: translationKey })
			: apiRoutes.getSurveyTranslation; // palautetaan tarkoituksella koko url, jota debuggaus on helpompaa
	return useQuery<string, AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<string>(ApiRootUrl + URL);
			return data;
		},
		enabled: surveyId !== undefined && translationKey !== undefined && translationKey.length > 0,
	});
};
