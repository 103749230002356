import { Configuration, LogLevel } from "@azure/msal-browser";

const b2cPolicies = {
	names: {
		signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
		//forgotPassword: "b2c_1_reset", // TODO ei vielä toteutettu. Reset pitäisi ohjata Cixtraan
		//editProfile: "b2c_1_edit_profile" // Ei käytässä
	},
	authorities: {
		signUpSignIn: {
			authority: "https://eezynetb2c.b2clogin.com/eezynetb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
		},
		//forgotPassword: {
		//    authority: "https://eezynetb2c.b2clogin.com/eezynetb2c.onmicrosoft.com/b2c_1_reset",
		//},
		//editProfile: {
		//    authority: "https://eezynetb2c.b2clogin.com/eezynetb2c.onmicrosoft.com/b2c_1_edit_profile"
		//}
	},
	authorityDomain: "eezynetb2c.b2clogin.com",
};

export const apiConfig = {
	b2cScopes: ["https://eezynetb2c.onmicrosoft.com/tasks-api/tasks.read"],
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	//auth: {
	//    clientId: "47762762-582a-4873-9a48-e6355a63d0d2",
	//    authority: "https://login.microsoftonline.com/common",
	//    redirectUri: "/",
	//    postLogoutRedirectUri: "/"
	//},

	// B2C authentication
	auth: {
		clientId: "22861a8f-e226-49a3-8524-f7ea6f43f4e1", // This is the ONLY mandatory field; everything else is optional.
		authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
		knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
		//redirectUri: "https://localhost:3000", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
		redirectUri: window.location.origin,
	},

	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (process.env.NODE_ENV === "production" || containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					// case LogLevel.Info:
					//     console.info(message);
					//     return;
					// case LogLevel.Verbose:
					//     console.debug(message);
					//     return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			},
		},
	},
};

export const scopes = ["user.read"];

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
// Add here scopes for id token to be used at MS Identity Platform endpoints.
//export const loginRequest: PopupRequest = {
//    scopes: ["User.Read"]
//};

// B2C loginRequest
export const loginRequest = {
	scopes: ["openid", ...apiConfig.b2cScopes],
};

// B2C tokenRequest
export const tokenRequest = {
	scopes: [...apiConfig.b2cScopes], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
	forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Eezynet Serverin rajapinta
export const apiRequest = {
	scopes: ["api://a8489db3-b7cf-4a63-a0e2-d609d9061c4b/.default"], // Eezynet_API
};
// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
